body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  min-height: 101vh;
  background: #07061c;
  // background: linear-gradient(135deg, rgba(22, 18, 87, 1) 0%, rgba(0, 0, 0, 1) 52%, rgba(22, 6, 39, 1) 93%);
}

#background {
  background: #f0f8ff0f !important;
  color: white;
}

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

table tr,
table td,
table th {
  color: white !important;
  background: transparent !important;
}
:root{
  --bs-border-color: #f0f8ff32;
}
table tr.bordered td, table tr.bordered th{
  border-bottom: 1px solid var(--bs-border-color);
  padding-bottom:15px;
}
table tr.padded-top td{
  padding-top: 15px;
}
.modal-content {
  border: #f0f8ff0f solid 1px;
  /* background: #f0f8ff0f !important; */
  background: linear-gradient(135deg, rgb(22, 18, 87) 0%, rgb(0, 0, 0) 52%, rgb(22, 6, 39) 93%) !important;
  color: white;
}

.modal-header {
  border-bottom: var(--bs-modal-header-border-width) solid #f0f8ff0f;
}

.modal-footer {
  border-top: var(--bs-modal-footer-border-width) solid #f0f8ff0f;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-40 {
  opacity: 0.4;
}

/* Base styling for all forex spans */
.fx {
  padding: 2px 5px;
  color: #fff; /* White text color */
}

/* Specific backgrounds for different currencies */
.fx-USD {
  background-color: rgba(10, 100, 200, 0.2); /* Blue background for USD */
}

.fx-EUR {
  background-color: rgba(200, 20, 60, 0.2); /* Red background for EUR */
}

.fx-JPY {
  color: red;
  background-color: #ffffffd9;
}

.fx-GBP {
  background-color: rgba(5, 180, 75, 0.2); /* Green background for GBP */
}

.fx-CAD {
  background-color: rgba(255, 165, 0, 0.2); /* Orange background for CAD */
}

.fx-AUD {
  background-color: rgba(0, 123, 255, 0.2); /* Light blue background for AUD */
}

.fx-MXN {
  background-color: rgba(26, 65, 4, 0.92);
}

.fx-DKK {
  background-color: rgba(128, 128, 0, 0.2); /* Olive green background for DKK */
}

.fx-NZD {
  background-color: rgba(0, 128, 0, 0.2); /* Dark green background for NZD */
}

.fx-SGD {
  background-color: rgba(255, 20, 147, 0.2); /* Deep pink background for SGD */
}

.fx-CHF {
  background-color: #8a2be25c;
}

.fx-SEK{
  background-color: #ffff009c;
  color: blue;
}

.fx-NOK{
  background-color: #f006;
}

.no-link {
  text-decoration: none;
  color: white;
}

.bg-warning {
  color: black;
}



.pointer {
  cursor: pointer;
}

.img-container {
  position: relative;
  background: rgba(34, 235, 198, 0.04) !important;
  padding-top: 60%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }
}


.container {
  width: 95%!important;
  max-width: 2200px !important;
}


// .recent-prices{
//   right: 0px;
//   position: absolute;
//   bottom: 0px;
//   max-width: 300px;
// }