/* Set the height of the swiper container to fill the entire viewport */
.trade-swiper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Style the trade item container */
.trade-item {
    width: 100%;
    max-width: 2300px;
    /* Adjust the max-width based on your layout */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

/* Ensure the Swiper container and slides take up the full height available */
.swiper-h {
    height: 100%;
}

/* Set a reasonable height for the images */
.comment-slide img {
    max-height: 70vh;
    width: auto;
    object-fit: contain;
    margin: auto;
    display: block;
    max-width: 100%;
}

.img-background{
    background-color: rgba(34, 235, 198, 0.04);
}

@media screen and (min-height: 1100px) and (orientation: landscape) {
    .comment-slide img {
        max-height: 79vh;
    }
}

/* Adjust the Swiper navigation buttons */
.swiper-button-prev,
.swiper-button-next {
    color: #fff;
}

.swiper-pagination-bullet {
    background-color: #fff;
}


.swiper-h {
    .swiper-pagination {
        bottom: 60px; // Adjust this value to move pagination buttons closer to the content
    }

    // .swiper-pagination-bullet {
    //   background: rgba(255, 255, 255, 0.6); // Style for inactive bullets
    //   width: 10px; // Bullet width
    //   height: 10px; // Bullet height
    // }

    // .swiper-pagination-bullet-active {
    //   background: #ffffff; // Style for the active bullet
    // }
}


.fullscreen-modal .modal-content {
    background: transparent;
    border: none;
}

.fullscreen-modal .modal-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.fullscreen-modal img {
    max-height: 100%;
    max-width: 100%;
}